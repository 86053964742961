<template>
  <wiki :slug="slug" />
</template>

<script>
import isMobile from "@/utils/mixins/isMobile";
import Wiki from "@/components/basics/Wiki";

export default {
  name: "Test",
  props: {
    slug: {
      type: String,
      default: null,
      validator: (prop) => typeof prop === "string" || prop === null,
    },
  },
  components: {
    Wiki,
  },
  mixins: [isMobile],
  data: () => ({}),
  computed: {},
  methods: {},
  mounted() {},
};
</script>
